<template>
  <div>
    <div
      class="bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/main-banner.jpg')"
    >
      <div class="px-2 py-1">
        <!-- navigation  -->
        <div class="container py-5">
          <div class="flex items-center justify-between md:justify-start">
            <div class="w-3/4 md:w-1/4 md:hidden">
              <img src="/images/main-logo.png" alt="" class="w-44 lg:w-52" />
            </div>
            <!-- mobile nav -->
            <div class="flex items-start justify-end w-1/4 md:hidden">
              <div
                @click="opened = !opened"
                class="flex items-center p-2 mt-2 cursor-pointer"
              >
                <h1 class="pr-2 text-sm text-pharma-blue">MENU</h1>
                <div>
                  <svg
                    class="w-6 h-6 fill-current text-pharma-blue"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <!-- desktop nav -->
          <div class="hidden md:flex md:justify-between">
            <img src="/images/main-logo.png" alt="" class="w-52">
            <ul
              class="flex items-center justify-between text-base font-medium text-pharma-blue lg:text-base"
            >
              <li
                class="mx-2 transition duration-300 ease-linear cursor-pointer hover:text-pharma-orange"
              >
                <a href="/" v-scroll-to="{ el: '', duration: 1500 }"> Home</a>
              </li>
              <li
                class="mx-2 transition duration-300 ease-linear cursor-pointer hover:text-pharma-orange"
              >
                <a href="#" v-scroll-to="{ el: '#about_us', duration: 1500 }">
                  About Us</a
                >
              </li>
              <li
                class="mx-2 transition duration-300 ease-linear cursor-pointer hover:text-pharma-orange"
              >
                <a href="#" v-scroll-to="{ el: '#products', duration: 1500 }">
                  Products</a
                >
              </li>
              <li
                class="mx-2 transition duration-300 ease-linear cursor-pointer hover:text-pharma-orange"
              >
                <a href="#" v-scroll-to="{ el: '#gallery', duration: 1500 }">
                  Gallery</a
                >
              </li>
              <li
                class="mx-2 transition duration-300 ease-linear cursor-pointer hover:text-pharma-orange"
              >
                <a href="#" v-scroll-to="{ el: '#contact', duration: 1500 }">
                  Contact Us</a
                >
              </li>
            </ul>
          </div>
        </div>

        <!-- overlay mobile nav -->
        <div v-show="opened" class="">
          <div class="font-medium text-center text-gray-600">
            <div>
              <a
                href="#"
                v-scroll-to="{ el: '#about_us', duration: 1500 }"
                class=""
              >
                <h1
                  class="py-2 transition duration-300 ease-linear hover:text-white hover:bg-pharma-orange"
                >
                  About Us
                </h1>
              </a>
            </div>
            <div>
              <a
                href="#"
                v-scroll-to="{ el: '#products', duration: 1500 }"
                class=""
              >
                <h1
                  class="py-2 transition duration-300 ease-linear hover:text-white hover:bg-pharma-orange"
                >
                  Products
                </h1>
              </a>
            </div>
            <div>
              <a
                href="#"
                v-scroll-to="{ el: '#gallery', duration: 1500 }"
                class=""
              >
                <h1
                  class="py-2 transition duration-300 ease-linear hover:text-white hover:bg-pharma-orange"
                >
                  Gallery
                </h1>
              </a>
            </div>
            <div>
              <a
                href="#"
                v-scroll-to="{ el: '#contact', duration: 1500 }"
                class=""
              >
                <h1
                  class="py-2 transition duration-300 ease-linear hover:text-white hover:bg-pharma-orange"
                >
                  Contact Us
                </h1>
              </a>
            </div>
          </div>
        </div>
        <!-- end navigation  -->

        <div class="container py-10 lg:py-20">
          <div class="lg:flex">
            <div class="lg:w-1/2 lg:pr-4">
              <carousel
                :autoplay="true"
                :loop="true"
                :dots="true"
                :autoplayTimeout="4500"
                :responsive="{ 0: { items: 1, nav: false } }"
              >
                <div v-for="(item, i) in Tagline" :key="i" class="">
                  <h1
                    class="pb-5 text-4xl font-semibold leading-tight text-pharma-blue"
                  >
                    {{ item.h1 }} <br class="hidden md:block lg:hidden">
                    <span class="text-pharma-orange">{{ item.span }}</span>
                  </h1>
                  <p class="text-sm text-gray-600">{{ item.p }}</p>
                </div>
              </carousel>
              <div class="pt-5">
                <h1
                  class="p-2 mx-auto text-center text-white transition duration-200 ease-linear transform rounded-lg shadow hover:bg-pharma-blue lg:mx-0 bg-pharma-orange w-52 hover:-translate-y-2"
                >
                  <a href="#" v-scroll-to="{ el: '#element', duration: 1500 }"
                    >Enquire Us Now</a
                  >
                </h1>
              </div>
            </div>
            <div class="pt-10 lg:w-1/2 lg:pt-0">
              <img
                src="/images/aed.png"
                alt=""
                class="w-full md:w-2/3 lg:w-4/5 md:mx-auto"
              />

              <div class="mt-1">
                <img src="/images/fukuda-mobile.png" alt="" class="md:hidden">
                 <img src="/images/fukuda-desktop.png" alt="" class="hidden md:block">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel";

export default {
  components: {
    carousel,
  },
  data() {
    return {
      opened: false,
      Tagline:[
        { h1:'Saving a life begins with the', span:'right equipment', p:'Our rugged, award-winning AEDs provide best in class performance under all conditions. The lifeline AED is simple and intuitive with a fully integrated design which means there are no lids, no moving parts, or exterior cases that could confuse or delay a rescuer in an emergency.'},
        { h1:"The power of Defibtech's", span:'singular focus', p:'Sudden cardiac arrest (SCA) is a public health crisis that we can start to remedy simply by having AEDs nearby, where we live, work and play. Our focus at Defibtech is on designing lifesaving products that can change the course of those first few minutes of an SCA.'},
        { h1:'Ease of use makes all the', span:'difference', p:'During an SCA, the chances of survival decrease by 10% every minute. Defibtech designs AEDs with simplicity in mind, like our Lifeline suite of products that take a rescuer through every step of the process with easy to follow verbal instructions and full color video.'},
        { h1:'FUKUDA X FUTURE', span:'', p:' Safe, speedy, accurate and user-friendly solutions that put the patient first.'},
      ]
    };
  },
};
</script>

<style>
</style>