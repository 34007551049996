<template>
  <div>
    <!-- content -->
    <h1 class="pb-2 text-xl font-medium text-center text-pharma-blue">
      Critical Care Monitoring Equipment
    </h1>
    <div class="w-10 h-0.5 mb-5 bg-pharma-orange mx-auto mt-2"></div>

    <!-- Tabs -->
    <div id="tabs" class="pt-6 md:flex md:flex-wrap lg:justify-center">
      <div
        v-for="(item, i) in critical_care"
        :key="i"
        class="w-full p-1 md:w-1/2 lg:w-1/4"
      >
        <div
          v-bind:class="[tab === item.tab ? 'active' : '']"
          @click="tab = item.tab"
          class="w-full mx-auto text-white transition duration-300 ease-linear rounded-lg cursor-pointer lg:text-sm lg:w-auto bg-pharma-blue hover:bg-pharma-orange"
        >
          <h2 v-html="item.sub" class="py-2 font-medium text-center uppercase">
            {{ item.sub }}
          </h2>
        </div>
      </div>
    </div>

    <!-- modal -->
    <div class="pt-10">
      <div v-for="(item, i) in modal_ecg" :key="i">
        <div v-show="tab == item.tab_list" class="md:flex md:items-center">
          <div class="md:w-1/2">
            <img :src="item.image" :alt="item.alt" class="lg:mx-auto" />
          </div>
          <div class="text-center md:text-left md:pl-4 md:w-1/2">
            <h1 class="text-xl font-medium text-pharma-blue">{{ item.alt }}</h1>
            <div class="bg-pharma-orange md:mx-0 w-10 h-0.5 mx-auto my-3"></div>
            <h1 class="text-xl font-bold text-gray-700 lg:text-2xl">
              Electrocardiograph <br />
              {{ item.h1 }}
            </h1>
            <p class="mt-4 text-base text-gray-600">
              {{ item.detail }}
            </p>

            <div class="pt-6">
              <h1 class="w-40 py-2 mx-auto text-center text-white bg-red-900 rounded-md md:mx-0">
               <a :href=" item.pdf +'.pdf'" target="_blank">More Info</a>
                </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tab: "tab-1",
      critical_care: [
        {
          tab: "tab-1",
          sub: "Electrocardiograph<br> CardiMax FCP-8100/FX-8100",
        },
        { tab: "tab-2", sub: "Electrocardiograph<br> CardiMax FX-8200" },
        { tab: "tab-3", sub: "Electrocardiograph<br>CardiMax FX-8300" },
        { tab: "tab-4", sub: "Electrocardiograph<br> CardiMax FX-8400" },
      ],
      modal_ecg: [
        {
          tab_list: "tab-1",
          image: "/images/ecg-1.png",
          alt: "Resting ECG",
          h1: "CardiMax FCP-8100/FX-8100",
          detail:
            "The weight is only 1.2kg and size is smaller than A4 size. But enables superior performance and allows a wide range applications.",
          pdf:'/images/pdf/FCP-8100_FX-8100_Catalogue_4P_compressed'
        },
        {
          tab_list: "tab-2",
          image: "/images/ecg-2.png",
          alt: "Resting ECG",
          h1: "CardiMax FX-8200",
          detail:
            "The weight is only 1.2kg and size is smaller than A4 size. But enables superior performance and allows a wide range applications.",
          pdf:'/images/pdf/FX-8200_Catalogue_4P_compressed'
        },
        {
          tab_list: "tab-3",
          image: "/images/ecg-3.png",
          alt: "Resting ECG",
          h1: "CardiMax FX-8300",
          detail:
            "The FX-8300 has a large 8-inch widescreen LCD offering unparalleled ease of use for checking patient data, as well as clear, accurate ECG waveforms. And for more effective identification of the presence of acute myocardial infarction, the FX-8300 combines the ACS diagnosis. With two additional functions: Synthesized 18-leads and an ACS diagnosis system.",
          pdf:'/images/pdf/FX-8300_Catalogue_compressed'
        },
        {
          tab_list: "tab-4",
          image: "/images/ecg-4.png",
          alt: "Stress Test",
          h1: "CardiMax FX-8400",
          detail:
            "The FX-8400 has a large 8-inch widescreen LCD offering unparalleled ease of use for checking patient data, as well as clear, accurate ECG waveforms. And for more effective identification of the presence of acute myocardial infarction, the FX-8400 combines the ACS diagnosis. With two additional functions: Synthesized 18-leads and an ACS diagnosis system.",
          pdf:'/images/pdf/FX-8400_Catalogrev5_compressed'
        },
      ],
    };
  },
};
</script>

<style></style>
