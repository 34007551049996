<template>
  <div class="w-11/12 mx-auto mt-2 md:flex md:flex-wrap lg:w-full">
    <div v-for="(item, index) in faq" :key="index" class="mb-1 md:w-1/2 md:px-1">
      <div
        class="flex flex-wrap items-center px-2 py-2 font-medium text-white rounded-t-lg cursor-pointer bg-pharma-blue md:text-center"
        @click="makeActive(index)"
        ref="question"
      >
        <p class="flex-1 text-base leading-tight text-center text-white md:text-sm">
          {{ item.q }}
        </p>
        <div
          class="px-2 text-2xl transition duration-200 delay-200 transform"
          :class="[qIndex === index ? 'rotate-45 ' : '']"
        >
          +
        </div>
      </div>

      <collapse-transition>
        <div
          v-html="item.a"
          v-show="qIndex === index ? true : false"
          class="p-4 text-sm text-gray-600 transition duration-500 ease-in-out transform border-2 border-gray-100 rounded-b-lg "
        >
          {{ item.a }}
        </div>
      </collapse-transition>
    </div>
  </div>
</template>

<script>
import CollapseTransition from "@ivanv/vue-collapse-transition";
export default {
  name: "Accordion",
  props: {
    faq: {
      type: Array,
      required: true,
    },
    focus: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    CollapseTransition,
  },
  data() {
    return {
      qIndex: -1,
    };
  },
  methods: {
    makeActive(index) {
      index === this.qIndex ? (this.qIndex = -1) : (this.qIndex = index);
      if (this.focus)
        this.$scrollTo(this.$refs.question[index], 500, { offset: -80 });
    },
  },
};
</script>

<style>
/* Border Style */
/* .borderCustom{border: 2px solid #c3a08d;}
.borderCustom:last-child{border: none;} */
</style>