<template>
  <div>
    <!-- main banner  -->
    <MainBanner2 />

    <div class="py-10">
      <div id="about_us" class="container lg:flex">
        <div class="lg:w-1/2 lg:pr-5">
          <h1 class="text-2xl font-semibold text-center lg:text-left">
            About Us
          </h1>
          <div class="w-10 h-0.5 bg-pharma-orange lg:mx-0 mx-auto mt-2"></div>
          <div
            class="pt-6 space-y-2 text-sm text-center text-gray-600 lg:text-left"
          >
            <p>
              <span class="font-bold"
                >Pharmaniaga Biomedical Sdn Bhd (PBSB)</span
              >
              was established in 1996 and 100% owned by
              <span class="font-bold">Pharmaniaga Logistics Sdn Bhd</span>, the
              largest integrated pharmaceutical group in Malaysia.
            </p>
            <p>
              Our business unit known as Medical Device Distribution (MDD) holds
              the expertise as being a one-stop solution provider for supply of
              medical devices which consist of medical equipment and consumables
              products to all public and private healthcare providers throughout
              this region.
            </p>
            <p>
              Our team consists of a combination of dedicated sales
              professionals, experienced technical experts and a reliable
              business support group in the medical equipment industry.
            </p>
            <p>
              MDD has successfully shown its presence through installation of
              various medical device in almost all hospital segments such as
              emergency departments, intensive care, critical care,
              anaesthesiology, haemodialysis and others.
            </p>
          </div>
        </div>
        <div class="pt-10 lg:pt-0 lg:w-1/2">
          <img
            src="/images/about-us.jpg"
            alt=""
            class="shadow md:w-2/3 lg:w-auto md:mx-auto"
          />
        </div>
      </div>
    </div>

    <!-- product section  -->
    <div id="products" class="relative md:h-52 lg:h-64">
      <div
        class="absolute top-0 left-0 w-full h-full bg-center bg-no-repeat bg-cover"
        style="background-image: url('/images/bg-product.png')"
      ></div>
      <div class="relative">
        <div class="container py-10 lg:pt-16">
          <h1 class="text-2xl font-semibold text-center text-white">
            Medical Equipment Products
          </h1>
          <div class="w-10 h-0.5 bg-white mx-auto mt-2"></div>
          <p
            class="pt-5 text-sm text-center text-white lg:mx-auto lg:max-w-3xl"
          >
            Driven by our motto Passion for Patients, we are committed to
            distributing renowned and high-quality medical equipment at
            affordable prices
          </p>
        </div>
      </div>
    </div>
    <div class="pb-10">
      <div class="container">
        <!-- Tabs -->
        <div id="tabs" class="pt-6 md:flex md:flex-wrap lg:justify-center">
          <div
            v-for="(item, i) in submenu"
            :key="i"
            class="w-full p-1 md:w-1/3 lg:w-1/4"
          >
            <div
              v-bind:class="[tab === item.tab ? 'active' : '']"
              @click="tab = item.tab"
              class="mx-auto text-white transition duration-300 ease-linear rounded-lg cursor-pointer w-60 lg:w-auto bg-pharma-blue hover:bg-pharma-orange"
            >
              <h2 class="py-2 font-medium text-center uppercase">
                {{ item.sub }}
              </h2>
            </div>
          </div>
        </div>

        <div class="pt-10">
          <div v-show="tab == 'tab-1'">
            <div class="pb-6">
              <h1
                class="text-xl font-medium text-center md:text-2xl text-pharma-blue"
              >
                AED Lifeline View
              </h1>
            </div>
            <!-- product features  -->
            <div class="lg:flex lg:items-center">
              <div class="lg:w-1/3">
                <img
                  src="/images/aed-1.jpg"
                  alt=""
                  class="h-full mx-auto md:w-1/3 lg:w-3/4"
                />
              </div>
              <div class="pt-5 lg:w-2/3">
                <h1 class="text-xl font-medium text-center text-gray-600">
                  Product Features
                </h1>
                <div
                  class="w-10 h-0.5 mb-10 bg-pharma-orange mx-auto mt-2"
                ></div>
                <div>
                  <Accordion :faq="faq" :focus="false" class="pt-4 lg:pt-0" />
                </div>
              </div>
            </div>
          </div>

          <div v-show="tab == 'tab-2'">
            <div class="pb-6">
              <h1
                class="text-xl font-medium text-center md:text-2xl text-pharma-blue"
              >
                AED Lifeline ECG
              </h1>
            </div>

            <!-- product feature  -->
            <div class="md:flex md:items-center">
              <img src="/images/aed-2.jpg" alt="" class="mx-auto md:w-1/3" />
              <div class="pt-6 md:pt-0 md:w-1/2">
                <h1
                  class="text-xl font-medium text-center text-gray-600 md:text-left"
                >
                  Product Features
                </h1>
                <div
                  class="w-10 h-0.5 mb-5 bg-pharma-orange mx-auto md:mx-0 mt-2"
                ></div>
                <div class="pl-3 text-sm text-gray-600">
                  <ul class="list-disc list-outside">
                    <li class="pb-2">
                      AED Mode: High resolution video display (animated
                      instructions and CPR coaching).
                    </li>
                    <li>
                      ECG Monitor Mode: Displays ECG data and event information
                      (with 3-lead electrodes).
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div v-show="tab == 'tab-3'">
            <div class="pb-6">
              <h1
                class="text-xl font-medium text-center md:text-2xl text-pharma-blue"
              >
                AED Lifeline PRO
              </h1>
            </div>
            <!-- product feature  -->
            <div class="md:flex md:items-center">
              <img src="/images/aed-3.jpg" alt="" class="mx-auto md:w-1/3" />
              <div class="pt-6 md:w-1/2 md:pt-0">
                <h1
                  class="text-xl font-medium text-center text-gray-600 md:text-left"
                >
                  Product Features
                </h1>
                <div
                  class="w-10 h-0.5 mb-5 bg-pharma-orange mx-auto md:mx-0 mt-2"
                ></div>
                <div class="pl-3 text-sm text-gray-600">
                  <ul class="list-disc list-outside">
                    <li class="pb-2">
                      AED Mode: High resolution video display (animated
                      instructions and CPR coaching).
                    </li>
                    <li class="pb-2">
                      ECG Monitor Mode: Displays ECG data and event information
                      (with 3-lead electrodes).
                    </li>
                    <li>
                      Manual Mode: Displays ECG data and event information and
                      defibrillation energy selection from 25-200 Joules with
                      charge, shock and disarm control.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Infusion And Syringe Pump  -->
    <div class="py-10">
      <div class="container">
        <h1 class="pb-2 text-xl font-medium text-center text-pharma-blue">
          Infusion And Syringe Pump
        </h1>
        <div class="w-10 h-0.5 mb-5 bg-pharma-orange mx-auto mt-2"></div>

        <div class="flex flex-wrap pt-6 md:justify-center">
          <div
            v-for="(item, i) in Syringe"
            :key="i"
            class="w-1/2 p-2 pb-2 md:w-1/3 lg:w-1/5"
          >
            <img :src="item.image" :alt="item.alt" class="mx-auto" />
            <h1
              v-html="item.alt"
              class="pt-4 text-sm font-medium leading-tight text-center md:text-base text-pharma-blue"
            >
              {{ item.alt }}
            </h1>
          </div>
        </div>
      </div>
    </div>

    <!-- Resting EGG -->
    <div class="py-10 bg-gray-50">
      <div class="container">
        <RestingEGG />
      </div>
    </div>

    <div class="py-10">
      <div class="container">
        <h1 class="pb-2 text-xl font-medium text-center text-pharma-blue">
          Others
        </h1>
        <div class="w-10 h-0.5 mb-5 bg-pharma-orange mx-auto mt-2"></div>
        <div class="">
          <div class="md:w-1/2 md:mx-auto">
            <img src="/images/home-1.jpg" alt="" class="w-2/3 mx-auto" />
            <h1
              class="pt-3 text-base font-semibold text-center text-pharma-blue"
            >
              Hemodialysis Consumables
            </h1>
          </div>
        </div>
      </div>
    </div>

    <!-- sub-whatsapp  -->
    <div class="py-10 bg-pharma-orange">
      <div class="container">
        <div
          class="text-center text-pharma-blue md:flex md:items-center md:justify-center"
        >
          <h1 class="text-xl font-bold md:pr-2">Want to Know More?</h1>
          <div class="pt-4 md:pt-0">
            <h1
              class="py-3 mx-auto text-base font-semibold text-center uppercase transition duration-300 ease-linear border-2 border-white hover:text-white hover:bg-pharma-blue w-72"
            >
              <a href="tel:1800888313"
                ><i class="pr-2 fa-solid fa-angles-right"></i>Call Us Now..</a
              >
            </h1>
          </div>
        </div>
      </div>
    </div>

    <!-- Our Gallery -->
    <div id="gallery" class="py-10">
      <div class="container">
        <h1 class="text-2xl font-semibold text-center text-pharma-blue">
          Our Gallery
        </h1>
        <div class="w-10 h-0.5 bg-pharma-orange mx-auto mt-2"></div>

        <carousel
          :margin="8"
          :autoplay="true"
          :loop="true"
          :dots="true"
          :responsive="{
            0: { items: 1, nav: false },
            600: { items: 2, nav: false },
            1024: { items: 3, nav: false },
          }"
        >
          <div v-for="(item, i) in gallery" :key="i" class="mt-6">
            <img :src="item.image" :alt="item.alt" class="mx-auto" />
            <h1 class="mt-3 text-sm text-center text-gray-600">
              {{ item.alt }}
            </h1>
          </div>
        </carousel>
      </div>
    </div>

    <!-- why choose us -->
    <div
      class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-choose.jpg')"
    >
      <div class="container">
        <h1 class="text-2xl font-semibold text-center text-white">
          Why Choose Us
        </h1>
        <div class="w-10 h-0.5 bg-white mx-auto mt-2"></div>

        <div class="flex flex-wrap lg:mt-6 md:justify-center">
          <div
            v-for="(item, i) in choose"
            :key="i"
            class="w-1/2 p-1 text-center text-white md:w-1/3 lg:w-1/5 mt-7"
          >
            <img :src="item.image" :alt="item.alt" class="w-20 mx-auto" />
            <h1 class="mt-2 text-sm">{{ item.alt }}</h1>
          </div>
        </div>
      </div>
    </div>

    <!-- youtube links -->
    <div class="py-10 bg-pharma-orange">
      <div class="container">
        <div class="md:flex md:flex-wrap">
          <div class="mb-2 md:w-1/3 md:px-1">
            <iframe
              width="100%"
              height="250"
              src="https://www.youtube.com/embed/Lqh39xJEaGI"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div class="mb-2 md:w-1/3 md:px-1">
            <iframe
              width="100%"
              height="250"
              src="https://www.youtube.com/embed/C_3XruE-E5w"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div class="md:w-1/3 md:px-1">
            <iframe
              width="100%"
              height="250"
              src="https://www.youtube.com/embed/Ktf-kMdk-HU"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>

    <!-- Our clients -->
    <div class="py-10">
      <div class="container">
        <h1 class="text-2xl font-semibold text-center">Our Supporters</h1>
        <div class="w-10 h-0.5 bg-pharma-orange mx-auto mt-2"></div>

        <div class="pt-6">
          <carousel
            :autoplay="true"
            :loop="true"
            :dots="false"
            :autoplayTimeout="2500"
            :responsive="{
              0: { items: 2, nav: false },
              600: { items: 4, nav: false },
              1000: { items: 5, nav: false },
            }"
          >
            <div v-for="image in brands" :key="image" class="px-1">
              <img :src="image" alt="" class="" />
            </div>
          </carousel>
        </div>
      </div>
    </div>

    <!-- enquire us -->
    <div
      id="element"
      class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-enquiry.jpg')"
    >
      <div class="container">
        <div class="pb-6">
          <h1 class="pb-4 text-3xl font-semibold text-center text-pharma-blue">
            Sent Us An <span class="text-pharma-orange">Enquiry</span>
          </h1>
          <p class="leading-tight text-center text-gray-800">
            If you have any comments or questions about our company and
            services, please contact us via phone, email or fax or you can also
            choose to fill in the feedback form below:
          </p>
        </div>
        <div class="lg:flex">
          <div
            class="px-5 py-6 bg-white rounded-md shadow lg:w-1/2 lg:py-10 md:mx-auto"
          >
            <EnquiryForm />
          </div>
          <div class="mt-4 lg:w-1/2 lg:mt-0 lg:pl-4">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.110077752635!2d101.4724139348877!3d3.0652371000000063!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc53017c5c27d5%3A0x2e6a90a080c26bd2!2sPharmaniaga%20Biomedical%20Sdn.%20Bhd.!5e0!3m2!1sen!2smy!4v1656051856329!5m2!1sen!2smy"
              width="100%"
              height="350"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>

    <!-- footer  -->
    <div id="contact" class="py-10">
      <div class="container">
        <div>
          <img
            src="/images/main-logo.png"
            alt=""
            class="w-4/5 mx-auto md:w-80"
          />
          <div class="pt-5 text-center text-gray-700">
            <h1 class="font-medium">
              Level 1, No. 23 Jalan Keluli 7/109, Kawasan Perindustrian Seksyen
              7, Klang, 40000 Shah Alam, Selangor, Malaysia
            </h1>
            <p class="pt-3">Monday to Friday: 9:00AM – 6:00PM</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Copyright -->
    <div class="py-3 bg-pharma-orange">
      <div class="container mx-auto">
        <h1 class="text-xs text-center text-pharma-blue lg:text-sm">
          Website maintained by Activa Media. All Rights Reserved.
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import EnquiryForm from "@/components/EnquiryForm.vue";
import carousel from "vue-owl-carousel";
import MainBanner2 from "@/components/MainBanner2.vue";
import Accordion from "@/components/Accordion.vue";
import RestingEGG from "@/components/RestingEGG.vue";

export default {
  components: {
    EnquiryForm,
    // MainBanner,
    carousel,
    MainBanner2,
    Accordion,
    RestingEGG,
  },
  data() {
    return {
      tab: "tab-1",
      submenu: [
        { tab: "tab-1", sub: "AED Lifeline View" },
        { tab: "tab-2", sub: "AED Lifeline ECG" },
        { tab: "tab-3", sub: "AED Lifeline PRO" },
      ],
      AED_Lifeline: [
        {
          h1: "Lifeline VIEW AED",
          p: " Meet the AED that’s taking easy-to-use to a whole new level. Other AEDs tell you what to do in an emergency. The Lifeline VIEW is the first and only one that shows you–with video in full-motion color. With award-winning design, durability, and easy maintenance – backed by the Defibtech commitment to innovation and excellence – we can guarantee you’ve never seen an AED like this before.",
        },
        {
          h1: "Full Color Interactive Display",
          p: "They say a picture is worth a thousand words; now multiply that with the power of video. The Lifeline VIEW has a large, full-color interactive display that gives you step-by-step instructions for performing CPR, rescue breathing, and external defibrillation.",
        },
        {
          h1: "Real-time Protocol Selection",
          p: " Not all rescuers are the same. Some people do compression-only CPR. Others may be trained to do both compression and rescue breathing. Real-time Protocol Selection allows you to switchover from one to both without skipping a beat, giving you the comfort and confidence to deliver emergency care your way.",
        },
        {
          h1: "Simple to Use ",
          sublist: [
            "One of the major problems with most AEDS is maintenance. Not with the Lifeline VIEW. The status screen gives you up to the minute information on maintenance if it's required. In addition, built-in video help gives you easy, step-by-step guidance on maintenance that supplements the user manual. Proactive reporting monitors the status of the device and its components",
            "As protocols change over time, your unit can be easily upgraded in the field with a data card.",
            "Data from the unit can be recorded or retrieved from the device using a data card.",
          ],
        },
        {
          h1: "Safe and Effective ",
          p: "The Lifeline VIEW uses biphasic technology, a clinically proven waveform and algorithm to deliver shocks and save lives. This is one of the most widely used impedance compensated waveforms which has extensive clinical experience-and success-in thousands of defibrillators around the world. Evaluation in hundreds of publications has demonstrated this technology to be the safest and most effective when it comes to saving lives.",
        },
        {
          h1: "A Personal Coach at Your Side ",
          p: " While you are in the middle of the rescue, you may not remember how many compressions to provide and the timing between each one. The Lifeline VIEW has a built-in metronome that counts out the beat so you know what to do and when to do it",
        },
        {
          h1: "Award-Winning Ergonomic Design",
          p: " While you are in the middle of the rescue, you may not remember how many compressions to provide and the timing between each one. The Lifeline VIEW has a built-in metronome that counts out the beat so you know what to do and when to do it.",
        },
        {
          h1: "Durable",
          p: "The VIEW's ruggedness is measured by a specific set of criteria. It is one tough little unit, coming out on top in some of the most demanding environmental tests.",
        },
      ],
      Syringe: [
        { image: "/images/pump-6.jpg", alt: "TOP SYRINGE PUMP,<br> TOP 5530RS" },
        { image: "/images/pump-7.jpg", alt: "TOP SYRINGE PUMP,<br> TOP-5520" },
        { image: "/images/pump-8.jpg", alt: "TOP INFUSION PUMP,<br> TOP 2300" },
        { image: "/images/pump-9.jpg", alt: "TOP INFUSION PUMP MJ-210" },
        {
          image: "/images/pump-5.jpg",
          alt: "Multi Lizer ML-100 <br> Ultraviolet Sterilizer",
        },
        { image: "/images/pump-3.jpg", alt: "TOP FEEDING PUMP,<br> TOP A-600" },
      ],
      client: [
        {
          h1: "Healthcare / Education",
          sublist: [
            "Hospital Sultanah Aminah, Johor",
            "Pejabat Kesihatan Daerah Kangar",
            "Pejabat Kesihatan Daerah Seremban",
            "Pejabat Kesihatan Melaka Tengah",
            "Klinik Kesihatan Tioman",
            "KPJ Ipoh",
            "Khoo Dental Surgery ",
            "Arthro Associates Clinic",
            "University Malaya Medical Centre (UMMC) ",
            "Universiti Institut Teknologi Mara (UiTM), Seri Iskandar ",
            "Universiti Malaysia, Kelantan (UMK) ",
            "SMK Mahmud Raub",
          ],
        },
        {
          h1: "Transport",
          sublist2: [
            {
              h2: "Malaysia Airport Holding Berhad (MAHB)",
              list2: [
                "Sultan Ismail Petra Airport, Kota Bharu",
                "Sultan Abdul Aziz Shah Airport, Subang",
                "Sandakan Airport",
                "Sultan Azlan Shah Airport, Ipoh ",
              ],
            },
          ],
          sublist: [
            "Airod",
            "Balfour Beatty Rail ",
            "Boustead Cruise Centre, Pulau Indah",
          ],
        },
        {
          h1: "Industrial",
          sublist: [
            "Shell Aviation",
            "Nestle Manufacturing",
            "Cargill Feed Malaysia ",
            "Lafarge Cement ",
            "DHL Asia Pacific",
            "Diamond Key International ",
            "Kerry Ingredients ",
            "Sony ECMS",
            "TechnipFMC ",
            "Pharmaniaga",
            "Idaman Pharma",
            "Chubb Insurance",
          ],
        },
        {
          h1: "Telecommunication",
          sublist: ["DIGI Telecommunications ", "NTT Communications"],
        },
        {
          h1: "Attraction / Entertainment",
          sublist: [
            "Genting Highland Premium Outlets",
            "Somerset Nusajaya",
            "Moet Hennessy Diageo Malaysia ",
            "UTC Seremban",
            "UTC Kangar",
          ],
        },
      ],
      brands: [
        "/images/brand-1.jpg",
        "/images/brand-2.jpg",
        "/images/brand-3.jpg",
        "/images/brand-4.jpg",
        "/images/brand-5.jpg",
        "/images/brand-6.jpg",
        "/images/brand-7.jpg",
        "/images/brand-8.jpg",
        "/images/brand-9.jpg",
        "/images/brand-10.jpg",
        "/images/brand-11.jpg",
        "/images/brand-12.jpg",
        "/images/brand-13.jpg",
      ],
      faq: [
        {
          q: "Lifeline VIEW AED",
          a: "Meet the AED that’s taking easy-to-use to a whole new level. Other AEDs tell you what to do in an emergency. The Lifeline VIEW is the first and only one that shows you–with video in full-motion color. With award-winning design, durability, and easy maintenance – backed by the Defibtech commitment to innovation and excellence – we can guarantee you’ve never seen an AED like this before.",
        },
        {
          q: "Full Color Interactive Display",
          a: "They say a picture is worth a thousand words; now multiply that with the power of video. The Lifeline VIEW has a large, full-color interactive display that gives you step-by-step instructions for performing CPR, rescue breathing, and external defibrillation.",
        },
        {
          q: "Real-time Protocol Selection",
          a: "Not all rescuers are the same. Some people do compression-only CPR. Others may be trained to do both compression and rescue breathing. Real-time Protocol Selection allows you to switchover from one to both without skipping a beat, giving you the comfort and confidence to deliver emergency care your way.",
        },
        {
          q: "Simple to Use",
          a: "One of the major problems with most AEDS is maintenance. Not with the Lifeline VIEW. The status screen gives you up to the minute information on maintenance if it's required. In addition, built-in video help gives you easy, step-by-step guidance on maintenance that supplements the user manual. Proactive reporting monitors the status of the device and its components <br><br>As protocols change over time, your unit can be easily upgraded in the field with a data card. Data from the unit can be recorded or retrieved from the device using a data card.",
        },
        {
          q: "Safe and Effective",
          a: "The Lifeline VIEW uses biphasic technology, a clinically proven waveform and algorithm to deliver shocks and save lives. This is one of the most widely used impedance compensated waveforms which has extensive clinical experience-and success-in thousands of defibrillators around the world. Evaluation in hundreds of publications has demonstrated this technology to be the safest and most effective when it comes to saving lives.",
        },
        {
          q: "A Personal Coach at Your Side",
          a: "While you are in the middle of the rescue, you may not remember how many compressions to provide and the timing between each one. The Lifeline VIEW has a built-in metronome that counts out the beat so you know what to do and when to do it",
        },
        {
          q: "Award-Winning Ergonomic Design",
          a: "While you are in the middle of the rescue, you may not remember how many compressions to provide and the timing between each one. The Lifeline VIEW has a built-in metronome that counts out the beat so you know what to do and when to do it.",
        },
        {
          q: "Durable",
          a: "The VIEW's ruggedness is measured by a specific set of criteria. It is one tough little unit, coming out on top in some of the most demanding environmental tests.",
        },
      ],
      choose: [
        {
          image: "/images/choose-1.png",
          alt: "Industry firsts including full-motion color video",
        },
        {
          image: "/images/choose-2.png",
          alt: "Semi-automatic or fully-automated functionality",
        },
        {
          image: "/images/choose-3.png",
          alt: "Easy,step-by-step instructions for rescue",
        },
        {
          image: "/images/choose-4.png",
          alt: "Rapid intervention for sudden cardiac arrest (SCA)",
        },
        {
          image: "/images/choose-5.png",
          alt: "Cost-effective therapy for emergencies in any setting",
        },
      ],
      gallery: [
        {
          image: "/images/gallery-1.jpg",
          alt: "Majlis Sambutan Hari Raya and Mesyuarat Agong Persatuan Pengusha Hemodialisis Bumiputera Malaysia Ke-16 (PPHBM)",
        },
        {
          image: "/images/gallery-2.jpg",
          alt: "Meeting with Defibtech Regional Product Manager",
        },
        {
          image: "/images/gallery-3.jpg",
          alt: "Meeting with Defibtech Regional Product Manager",
        },
        {
          image: "/images/gallery-4.jpg",
          alt: "AED Demonstration at St John Ambulance Malaysia ",
        },
        {
          image: "/images/gallery-5.jpg",
          alt: "AED user training at Sam Tec ",
        },
        {
          image: "/images/gallery-6.jpg",
          alt: "Installation of UV sterilizer at Bestari Hemodialysis ",
        },
        {
          image: "/images/gallery-7.jpg",
          alt: "Patient Monitor Demonstriation at Hospital Al Islam",
        },
        {
          image: "/images/gallery-8.jpg",
          alt: "Kursus Pain management for (PCA pump demonstration) at Hospital Slim River",
        },
        {
          image: "/images/gallery-9.jpg",
          alt: "TNC and User training at QSR Petaling Jaya ",
        },
        {
          image: "/images/gallery-10.jpg",
          alt: "Business Support MDD",
        },
      ],
    };
  },
};
</script>

<style>
#tabs .active {
  background-color: #ffa821;
}
</style>
